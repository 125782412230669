import {UserDto} from "../../../Models/Dto/UserDto";
import {HttpConnection} from "../Designer/RequestCreator";


export class UserApiController {
  private readonly route = `users/json`;

  public async getAllInEnvironment(environment?: string): Promise<UserDto[]> {
    if (environment) {
      return await HttpConnection.getAsync("", `${environment}/` + this.route, false) as UserDto[];
    } else {
      return await HttpConnection.getAsync("", this.route, true) as UserDto[];
    }
  }

  public async getAll(): Promise<UserDto[]> {
    return await HttpConnection.getAsync("", this.route, false) as UserDto[];
  }

  public async get(id: string): Promise<UserDto> {
    return await HttpConnection.getAsync(id, this.route, false) as UserDto;
  }

  private currentUser?: UserDto;

  public async getCurrentUser(): Promise<UserDto> {
    if (this.currentUser) return this.currentUser;

    this.currentUser = await HttpConnection.getAsync("", `${this.route}/getCurrentUser`, false) as UserDto;
    return this.currentUser;
  }

  public async putCurrentUser(dto: UserDto): Promise<UserDto> {
    this.currentUser = await HttpConnection.putAsync(dto, `${this.route}/putCurrentUser`, false) as UserDto;
    return this.currentUser;
  }

  public changeEmail(newEmailAddress: string): Promise<void> {
    return HttpConnection.putAsync(newEmailAddress, `${this.route}/changeEmail`, false) as Promise<void>;
  }

  public changePassword(currentPassword: string, newPassword: string): Promise<void> {
    return HttpConnection.putAsync({
      currentPassword: currentPassword,
      newPassword: newPassword
    }, `${this.route}/changePassword`, false) as Promise<void>;
  }

  public async getAvatar(userId: string) {
    return await HttpConnection.getAsync("", `users/${userId}/avatar`, false) as UserDto;
  }

  public async uploadAvatar(userId: string, formData: FormData): Promise<any> {
    return fetch(`/users/${userId}/uploadAvatar`, {
      method: 'POST',
      body: formData
    });
  }
}