import {HttpConnection} from "../Designer/RequestCreator";

/**
 * Interface for the JSON API provided by the TDM 5 website. Its methods and routes should match those in JsonApiController.cs.
 */
export class JsonApiController<TDto> {
  protected readonly route;

  constructor(path: string) {
    this.route = `${path}/json`;
  }

  public getAll(env?: string): Promise<TDto[]> {
    return HttpConnection.getAsync("", (env ? env + "/" : "") + this.route, !env) as Promise<TDto[]>;
  }

  public getFolderContent(folderId?: string, env?: string): Promise<TDto[]> {
    return HttpConnection.getAsync(null, (env ? env + "/" : "") + this.route + "/folder?id=" + (folderId ? folderId : ""), !env) as Promise<TDto[]>;
  }

  public get(id: string, env?: string): Promise<TDto> {
    return HttpConnection.getAsync(id, (env ? env + "/" : "") + this.route, !env) as Promise<TDto>;
  }

  public post(dto: TDto, env?: string): Promise<TDto> {
    return HttpConnection.postAsync(dto, (env ? env + "/" : "") + this.route, !env) as Promise<TDto>;
  }

  public put(dto: TDto, id: string, env?: string): Promise<TDto> {
    return HttpConnection.putAsync(dto, `${env ? env + "/" : ""}${this.route}/${id}`) as Promise<TDto>;
  }

  public move(id: string, toFolder: string, env?: string) {
    return HttpConnection.postAsync(null, `${(env ? env + "/" : "")}${this.route}/${id}/moveTo?folderId=${toFolder ? toFolder : ""}`, !env) as Promise<TDto[]>;
  }

  public rename(id: string, name: string, env?: string) {
    return HttpConnection.postAsync(null, `${(env ? env + "/" : "")}${this.route}/${id}/rename?name=${name}`, !env) as Promise<TDto[]>;
  }

  public delete(id: string, env?: string): Promise<any> {
    return HttpConnection.deleteAsync(id, (env ? env + "/" : "") + this.route, !env);
  }

  public clone(id: string, env?: string): Promise<TDto> {
    return HttpConnection.postAsync("", `${env ? env + "/" : ""}${this.route}/${id}/clone`, !env) as Promise<TDto>;
  }
}