import {JsonApiController} from "./JsonApiController";
import {EnvironmentInvitationDto} from "../../../Models/Dto/EnvironmentInvitationDto";
import {DataSourceApiController} from "./DataSourceApiController";
import {UserRoleDto} from "../../../Models/Dto/UserRoleDto";
import {MediaApiController} from "./MediaApiController";
import {PlayerApiController} from "./PlayerApiController";
import {EnvironmentApiController} from "./EnvironmentApiController";
import {UserApiController} from "./UserApiController";
import {PlayerGroupDto} from "../../../Models/Dto/PlayerGroupDto";
import {FileSystemApiController} from "./FileSystemApiController";
import {ContentController} from "./ContentController";
import {ScheduleDto} from "../../../Models/Dto/Schedule/ScheduleDto";
import {ElementDto} from "../../../Models/Dto/ElementDto";
import {EnvironmentInvitationApiController} from "./EnvironmentInvitationApiController";
import {HttpConnection} from "../Designer/RequestCreator";
import {SearchResultDto} from "../../../Models/Dto/SearchResultDto";
import {FontFamilyDto} from "../../../Models/Dto/FontFamilyDto";
import {FontApiController} from "./FontController";
import {DataSourceDto} from "../../../Models/Dto/DataSources/DataSourceDto";
import {ResourcePermissionController} from "./ResourcePermissionController";
import {ConnectionController} from "./ConnectionController";
import {CalendarController} from "./CalendarController";
import {ActionPermission} from "../../../Models/ActionPermission";
import {ActionPermissionService} from "./ActionPermissionService";
import {PageFolderDto} from "../../../Models/Dto/PageFolderDto";
import {PlaylistFolderDto} from "../../../Models/Dto/PlaylistFolderDto";
import {MediaItemDto} from "../../../Models/Dto/MediaItems/MediaItemDto";
import {PlaylistController} from "./PlaylistController";
import {UtilController} from "./UtilController";
import {PageDto} from "../../../Models/Dto/PageDto";

export class TdmApi {
  public static players = new PlayerApiController();
  public static playerGroups = new JsonApiController<PlayerGroupDto>("playerGroups");
  public static playlists = new PlaylistController();
  public static playlistFolders = new JsonApiController<PlaylistFolderDto>('playlistFolders');
  public static pages = new JsonApiController<PageDto>('pages');
  public static pageFolders = new JsonApiController<PageFolderDto>('pageFolders');
  public static elements = new JsonApiController<ElementDto>('elements');
  public static media = new MediaApiController<MediaItemDto>('media');
  public static dataSources = new DataSourceApiController<DataSourceDto>('dataSources');
  public static environments = new EnvironmentApiController();
  public static environmentInvitations = new EnvironmentInvitationApiController<EnvironmentInvitationDto>("invitations");
  public static users = new UserApiController();
  public static userRoles = new JsonApiController<UserRoleDto>('userRoles');
  public static content = new ContentController();
  public static fileSystem = new FileSystemApiController();
  public static schedules = new JsonApiController<ScheduleDto>("schedules");
  public static fonts = new FontApiController();
  public static fontFamilies = new JsonApiController<FontFamilyDto>("fontFamilies");
  public static connections = new ConnectionController();
  public static calendars = new CalendarController();
  public static util = new UtilController();
  public static search = (query: string) => HttpConnection.getAsync(null, 'search?q=' + encodeURIComponent(query)) as Promise<SearchResultDto[]>;
  public static resourcePermissions = new ResourcePermissionController();
  public static actions = new ActionPermissionService();

  static allowed(permission?: ActionPermission) {
    if (!permission) return true;

    return TdmApi.actions.allowed(permission);
  }
}