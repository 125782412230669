//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActionPermission {
  Pages = 0,
  PageOverview = 1,
  PageView = 2,
  PageEdit = 3,
  PageCreate = 4,
  PageDelete = 5,
  PageAll = 6,
  Playlists = 16,
  PlaylistOverview = 17,
  PlaylistView = 18,
  PlaylistEdit = 19,
  PlaylistCreate = 20,
  PlaylistDelete = 21,
  PlaylistAll = 22,
  Players = 32,
  PlayerOverview = 33,
  PlayerRegister = 34,
  PlayerConfigure = 35,
  PlayerControl = 36,
  PlayerAssignContent = 37,
  PlayerDelete = 38,
  PlayerAll = 39,
  Media = 48,
  MediaOverview = 49,
  MediaView = 50,
  MediaEdit = 51,
  MediaUpload = 52,
  MediaDelete = 53,
  MediaAll = 54,
  DataSources = 64,
  DataSourceOverview = 65,
  DataSourceView = 66,
  DataSourceEdit = 67,
  DataSourceCreate = 68,
  DataSourceDelete = 69,
  DataSourceAll = 70,
  Schedules = 80,
  ScheduleOverview = 81,
  ScheduleView = 82,
  ScheduleEdit = 83,
  ScheduleCreate = 84,
  ScheduleDelete = 85,
  ScheduleAll = 86,
  Fonts = 96,
  FontOverview = 97,
  FontView = 98,
  FontEdit = 99,
  FontCreate = 100,
  FontDelete = 101,
  FontAll = 102,
  Environments = 112,
  EnvironmentManage = 113,
  EnvironmentInviteUser = 114,
  EnvironmentSetUserRole = 115,
  EnvironmentSetAdminRole = 116
}
