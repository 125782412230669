import React, {useState} from "react";
import styles from "./SearchBar.scss";
import {SearchResultDto} from "../Models/Dto/SearchResultDto";
import {TdmApi} from "../wwwroot/Scripts/Controllers/TdmApi";
import {HttpConnection} from "../wwwroot/Scripts/Designer/RequestCreator";
import {useTranslation} from "react-i18next";
import Thumbnail from "../wwwroot/Scripts/Designer/Components/Thumbnail";

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResultDto[]>([]);

  const {t} = useTranslation();
  const environment = location.pathname != '/environments' ? HttpConnection.environmentPath : null;

  function onChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const query = e.target.value;

    setQuery(query);
    setSearching(true);

    TdmApi.search(query).then(results => {
      console.log(results);
      setSearching(false);
      setResults(results);
    });
  }

  if (!environment) return <></>;

  return <div className={styles.searchBar}>
    <input type="text"
           className={styles.queryInput}
           value={query}
           placeholder={t("Search anywhere in your environment")}
           onChange={(e) => onChanged(e)}
    />

    {query &&
      <div className={styles.searchResultsContainer}>
        {(results && results.length > 0)
          ? results.map(result => <SearchResult key={result.id} result={result}/>)
          : searching ? <p>Searching...</p>
            : <p>No results found...</p>}
      </div>
    }
  </div>;
};

interface SearchResultParameters {
  result: SearchResultDto;
}

function urlForSearchResult(result: SearchResultDto) {
  const env = HttpConnection.environmentPath;
  switch (result.type) {
    case "Page":
      return `/${env}/pages/${result.id}`;
    case "Playlist":
      return `/${env}/playlists/${result.id}`;
    case "Player":
      return `/${env}/players/${result.id}`;
    case "PlayerGroup":
      return `/${env}/playerGroups/${result.id}`;
    case "DataSource":
      return `/${env}/dataSources/${result.id}`;
    case "FontFamily":
      return `/${env}/fontFamilies/${result.id}`;
  }
}

const SearchResult: React.FC<SearchResultParameters> = ({result}) => {
  return <div className={styles.searchResult}>
    <Thumbnail alt={""} lastUpdated={new Date()} id={result.id} style={{width: "80px", height: "40px", objectFit:"contain"}}/>
    {/*<img src='https://placehold.co/80x45' alt='placeholder image'></img>*/}
    <div>
      <a href={urlForSearchResult(result)} className={styles.searchResultTitle}>{result.name}</a>
      <span className={styles.searchResultType}>{result.type}</span>
    </div>
  </div>;
};

export default SearchBar;