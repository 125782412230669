import i18n from "i18next";

type Language = {
  languageCode: string
  label: string
  flagCode: string
}

export class SupportedLanguages {

  private static SupportedLanguages: Language[] = [
    {languageCode: "en", label: "English", flagCode: "GB"},
    //{languageCode : "en-US" , label : "American", flagCode: "US"},
    {languageCode: "nl", label: "Dutch", flagCode: "NL"},
    /*{languageCode : "fr" , label : "French", flagCode: "FR"},
    {languageCode : "de" , label : "German", flagCode: "DE"},
    {languageCode : "pl" , label : "Polish", flagCode: "PL"},
    {languageCode : "es" , label : "Spanish", flagCode: "ES"},
    {languageCode : "it" , label : "Italian", flagCode: "IT"}*/
  ];

  public static getCountryCodes(): string[] {
    return this.SupportedLanguages.map(lang => lang.languageCode);
  }

  public static getCountryFlagCode(): string[] {
    return this.SupportedLanguages.map(lang => lang.flagCode);
  }

  public static getCountryLabels() {
    const result: { [key: string]: string } = {};
    this.SupportedLanguages.map(language => result[language.flagCode] = i18n.t(language.label));
    return result;
  }

  public static getLanguageCodeByFlagCode(flagCode: string): string {
    return this.SupportedLanguages.find(e => e.flagCode === flagCode)?.languageCode;
  }

  public static getFlagCodeByCountyCode(countryCode: string): string {
    return this.SupportedLanguages.find(e => e.languageCode === countryCode)?.flagCode;
  }
}