export class TdmUrl {
  public static root = `${window.location.protocol}//${window.location.host}/`;
  public static account = {
    login: (returnUrl?: string) => '/account/login' + (returnUrl ? '?returnUrl=' + encodeURIComponent(returnUrl) : ''),
    register: () => '/account/register'
  };
  private static currentEnvironment: string = window.location.pathname.split('/')[1];
  public static environments = {
    environments: '/environments',
    join: (invitationId: string, environment?: string) => `${environment ?? TdmUrl.currentEnvironment}/join/${invitationId}`
  };
}