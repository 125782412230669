import React, {useEffect, useState} from "react";
import {HttpConnection} from "../Designer/RequestCreator";
import styles from "./Menu.scss";
import {useTranslation} from "react-i18next";
import Avatar from "../../../ReactComponents/Avatar";
import {EnvironmentDto} from "../../../Models/Dto/EnvironmentDto";
import {TdmApi} from "../Controllers/TdmApi";
import {ActionPermission} from "../../../Models/ActionPermission";
import ReactDOMClient from "react-dom/client";
import ComingSoon from "../../../ReactComponents/ComingSoon";

type MenuItem = {
  name: string;
  title: string;
  icon?: string;
  items: { name: string, path: string, contains?: string[], permissions?: ActionPermission[] }[];
  path?: string;
  comingSoon?: boolean;
}

type MenuProps = {
  menuItems?: MenuItem[];
}

const MenuWrapper = () => {
  if (HttpConnection.environmentPath === null)
    return <Menu menuItems={[]}/>;

  //TODO: add ActionPermissions to this, including hiding a menuItem if none of the subMenuItems are allowed...

  const menuItems: MenuItem[] = [
    {
      name: "dashboard",
      title: "Dashboard",
      icon: "application",
      path: "dashboard",
      items: []
    },
    {
      name: "designer",
      title: "Designer",
      icon: "palette",
      items: [
        {name: "Players", path: "players", contains: ["playerGroups"], permissions: [ActionPermission.PlayerOverview]},
        {name: "Pages", path: "pages", permissions: [ActionPermission.PageOverview]},
        {name: "Playlists", path: "playlists", permissions: [ActionPermission.PlaylistOverview]},
        {name: "Media", path: "media", permissions: [ActionPermission.MediaOverview]},
        {name: "Schedules", path: "schedules", permissions: [ActionPermission.ScheduleOverview]},
        {name: "Fonts", path: "fontFamilies", permissions: [ActionPermission.FontOverview]}
      ]
    },
    {
      name: "interactions",
      title: "Interactions",
      icon: "data",
      items: [
        {name: "Data sources", path: "dataSources", permissions: [ActionPermission.DataSourceOverview]},
      ],
    },
    /*{
      name: "roombooking",
      title: "Room booking",
      icon: "cube",
      items: [],
      comingSoon: true
    }*/
  ];

  return (
    <>
      <Menu menuItems={menuItems}/>
    </>
  );
};

const Menu = ({menuItems}: MenuProps) => {
  const [expandedSubmenu, setExpandedSubmenu] = useState<string>("");
  const [expandedMenu, setExpandedMenu] = useState<string>("");
  const [environment, setEnvironment] = useState<EnvironmentDto>(null);
  const {t} = useTranslation();

  useEffect(() => {
    TdmApi.environments.getByPath(HttpConnection.environmentPath)
      .then(setEnvironment);
  }, []);

  function isMenuItemActive(item: MenuItem): boolean {
    return item.items.some(x => isSubmenuLinkCurrent(x));
  }

  function menuItemAllowed(item: MenuItem): boolean {
    return !item.items || item.items.length == 0 || item.items.some(x => subMenuItemAllowed(x));
  }

  function subMenuItemAllowed(item: { permissions?: ActionPermission[] }): boolean {
    return !item.permissions || item.permissions.length == 0 || item.permissions.some(perm => TdmApi.allowed(perm));
  }

  function isSubmenuLinkCurrent(item: { name: string, path: string, contains?: string[] }): boolean {
    const href = `/${HttpConnection.environmentPath}/${item.path}`;
    if (location.pathname.startsWith(href)) return true;
    return item.contains?.some(path => location.pathname.startsWith(`/${HttpConnection.environmentPath}/${path}`)) ?? false;
  }

  const handleSubmenuClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const submenuName = event.currentTarget.dataset.submenu;
    if (submenuName) {
      const menu = menuItems.find(x => x.name === submenuName);
      if (menu && menu.items.length > 0) {
        setExpandedSubmenu((prevState) => (prevState === submenuName ? "" : submenuName));
      } else {
        setExpandedSubmenu("");
      }
    }
  };
  const expandMenu = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const menu = event.currentTarget.dataset.menu;
    if (menu) {
      setExpandedMenu((prevState) => (prevState === menu ? "" : menu));
    }
  };

  return (
    <>
      <div className={`${styles.menu} ${expandedMenu === "mainMenu" ? styles.expanded : ""}`}>
        <div className={styles.logoWrapper}>
          {environment
            ? <Avatar environment={environment} className={styles.logo}/>
            : <a className={`${styles.logo} ${styles.placeholder}`}>
              <img alt="logo" src="/img/shared/logo-tdm-sm.svg"/>
            </a>
          }
        </div>
        <div className={styles.menuItems}>
          {menuItems.map((menu) => menuItemAllowed(menu) ?
            menu.comingSoon ?
              <ComingSoon functionalityName={menu.name}><a
                href={menu.path != null ? `/${HttpConnection.environmentPath}/${menu.path}` : null} key={menu.name}
                data-submenu={menu.name}
                className={`${expandedSubmenu === menu.name ? styles.active : ""} ${isMenuItemActive(menu) ? styles.current : ""}`}
                onClick={handleSubmenuClick}>
                <i className={`icon icon-${menu.icon}`}></i>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>{t(menu.title)}</span>
                </div>
              </a></ComingSoon>
              :
              <a href={menu.path != null ? `/${HttpConnection.environmentPath}/${menu.path}` : null} key={menu.name}
                 data-submenu={menu.name}
                 className={`${expandedSubmenu === menu.name ? styles.active : ""} ${isMenuItemActive(menu) ? styles.current : ""}`}
                 onClick={handleSubmenuClick}>
                <i className={`icon icon-${menu.icon}`}></i>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>{t(menu.title)}</span>
                </div>
              </a> : <></>
          )}

          <div className={styles.menuSpacer}/>
          {/*<a href="#">*/}
          {/*  <i className="icon icon-trash"/>*/}
          {/*  <div className={styles.labelWrapper}><span className={styles.label}>{t("Deleted items")}</span></div>*/}
          {/*</a>*/}
          {/*<a href="#">*/}
          {/*  <i className="icon icon-help"/>*/}
          {/*  <div className={styles.labelWrapper}><span className={styles.label}>{t("Support center")}</span></div>*/}
          {/*</a>*/}
          <a href="/account/logout">
            <i className="icon icon-logout"/>
            <div className={styles.labelWrapper}><span className={styles.label}>{t("Log out")}</span></div>
          </a>
        </div>
        <div className={styles.expandButton}>
          <a onClick={expandMenu} data-menu="mainMenu" className={styles.collapsed}><i
            className="icon icon-arrow-short"></i></a>
        </div>
      </div>
      <div className={styles.submenus}>
        {menuItems.filter((menu) => (menu.items.length > 0)).map((menu) => (
          <div
            key={menu.name}
            className={`${styles.submenu} ${expandedSubmenu === menu.name ? styles.expanded : ""}`}
            data-submenu={menu.name}>
            <div className={styles.submenuContent}>
              <div className={styles.submenuHeader}>
                <h4>{menu.title}</h4>
              </div>
              <ul className={styles.submenuItems}>
                {menu.items.map((item) => subMenuItemAllowed(item) ?
                  <a key={item.name} href={`/${HttpConnection.environmentPath}/${item.path}`}
                     className={isSubmenuLinkCurrent(item) ? styles.current : ""}>
                    <span>{item.name}</span>
                  </a> : <></>
                )}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const MenuRenderer = {
  render: () => {
    const menuWrapper = document.getElementById('menu-wrapper') as HTMLDivElement;
    if (menuWrapper != null) {
      const wrapperContainer = ReactDOMClient.createRoot(menuWrapper);
      wrapperContainer.render(<MenuWrapper/>);
    }
  }
};

export {MenuRenderer};