import {SupportedLanguages} from "./SupportedLanguages";
import i18next from 'i18next';
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import {HttpConnection} from "../Designer/RequestCreator";
import {initReactI18next} from 'react-i18next';


i18next
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    saveMissing: true,
    saveMissingTo: "all",
    saveMissingPlurals: true,
    ns: [
      'common',
      'designer'
    ],
    supportedLngs: SupportedLanguages.getCountryCodes(),
    defaultNS: "common",
    backend: {
      backends: [
        LocalStorageBackend,
        HttpBackend
      ],
      backendOptions: [
        //LocalStorageBackend options
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
        },
        //HttpBackend options
        {
          loadPath: `/${HttpConnection.environmentPath}/Locales/{{lng}}/{{ns}}`,
          addPath: `/${HttpConnection.environmentPath}/Locales/{{lng}}/{{ns}}`,
        }]
    },
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage', 'navigator'],

      lookupCookie: 'i18next',

      caches: ['cookie'],

      cookieMinutes: 10,
      cookieDomain: window.location.hostname,
    }
  });
export default i18next;
