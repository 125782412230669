
import {HttpConnection} from "../Designer/RequestCreator";
import {JsonApiController} from "./JsonApiController";
import {Dashboard, Report, Tile} from "../../../ReactComponents/Designer/Properties/PowerBiProperties";

export class DataSourceApiController<MediaItemDto> extends JsonApiController<MediaItemDto> {
    constructor(path) {
        super(path);
    }

    public async GetCoordinatesFromLocation(location: string): Promise<LocationResult[]> {
        return await HttpConnection.getAsync(location, `${this.route}/GetCoordinatesFromLocation`) as LocationResult[];
    }

    public GetGrantPowerBiPermissionUrl(id: string): string {
        return `/${HttpConnection.environmentPath}/${this.route}/GrandPowerBiPermission/${id}`;
    }

    public async getFacebookPages(dataSourceId: string): Promise<FacebookPagesDto> {
        return await HttpConnection.getAsync(null, `${this.route}/GetFacebookPages/${dataSourceId}`);
    }

    public GetInstagramLoginUrl(id: string): string {
        return `/${HttpConnection.environmentPath}/${this.route}/GetInstagramLoginUrl/${id}`;
    }

    public async FetchCustomDataSource(dataSourceId: string): Promise<ArrayBuffer> {
        const response = await fetch(`/${HttpConnection.environmentPath}/${this.route}/FetchCustomDataSource/${dataSourceId}`); // Replace with your actual endpoint URL
        if (response.ok) {
            return await response.arrayBuffer();
        }
    }

    public async getPowerBiDashboards(dataSourceId: string): Promise<Dashboard[]> {
        return await HttpConnection.getAsync(null, `${this.route}/GetPowerBiDashboards/${dataSourceId}`);
    }

    public async getPowerBiReports(dataSourceId: string): Promise<Report[]> {
        return await HttpConnection.getAsync(null, `${this.route}/GetPowerBiReports/${dataSourceId}`);
    }

    public async getPowerBiTiles(dataSourceId: string, dashboardId: string): Promise<Tile[]> {
        return await HttpConnection.getAsync(null, `${this.route}/GetPowerBiTiles/${dataSourceId}?dashboardId=${dashboardId}`);
    }
}

export type LocationResult = {
    name?: string,
    localNames? : string[],
    lat?: number,
    lon?: number,
    country?: string,
    state?: string,
}

interface CategoryListDto {
    id: string;
    name: string;
}

export interface PageDataDto {
    access_token: string;
    category: string;
    category_list: CategoryListDto[];
    name: string;
    id: string;
    tasks: string[];
}

export interface FacebookPagesDto {
    data?: PageDataDto[];
}
