import React, {useEffect, useState} from 'react';
import {Defaults} from "../../Tdm/Defaults";
import {HttpConnection} from "../RequestCreator";
import Skeleton from "react-loading-skeleton";
import styles from "./ListGridItem.scss";

interface props {
  alt: string
  id?: string
  lastUpdated: Date
  size?: ThumbnailSize
  className?: string
  fallBackImage?: string
  style?: React.CSSProperties,
  noLoader?: boolean,
  loaderClass?: string
}

const Thumbnail: React.FC<props> = ({
                                      alt,
                                      id = null,
                                      className,
                                      lastUpdated,
                                      fallBackImage,
                                      style,
                                      noLoader = false,
                                      loaderClass = ""
                                    }) => {
  const maxRetries = 5;
  const retryInterval = 2000;

  const lastUpdatedTime = lastUpdated ? new Date(lastUpdated).getTime() : new Date().getTime();
  const path = `/${HttpConnection.environmentPath}/content/getThumbnail/${encodeURIComponent(id)}?lastUpdated=${lastUpdatedTime}`;

  const [imageSrc, setImageSrc] = useState<string>(id
    ? path
    : (fallBackImage ?? Defaults.IMAGE_URL)
  );
  const [retries, setRetries] = useState<number>(0);
  let retryTimeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    setImageSrc(id
      ? path
      : (fallBackImage ?? Defaults.IMAGE_URL));
    setRetries(0);
    retryTimeout && clearTimeout(retryTimeout);
  }, [id]);

  const onError = (error: React.SyntheticEvent<HTMLImageElement>) => {
    // console.log(error)
    setImageSrc(fallBackImage ?? Defaults.IMAGE_URL);
    if (retries >= maxRetries)
      return;
    retryTimeout = setTimeout(() => {
      setRetries(retries + 1);
      setImageSrc(imageSrc);
    }, retryInterval);
  };

  return (
    <>
      <img src={imageSrc} alt={alt} onError={onError} className={className} draggable={false} style={style}/>
      {!noLoader && imageSrc == Defaults.IMAGE_URL ?
        <Skeleton className={`${styles.skeletonImage} ${loaderClass}`}/> : null}
    </>
  );
};

export default Thumbnail;

export enum ThumbnailSize {
  Small,
  Medium,
  Large,
  Big
}