import {JsonApiController} from "./JsonApiController";
import {PlayerDto} from "../../../Models/Dto/PlayerDto";
import {HttpConnection} from "../Designer/RequestCreator";
import {PlayerRegistrationDto} from "../../../Models/Dto/PlayerRegistrationDto";


export class PlayerApiController extends JsonApiController<PlayerDto> {
  public constructor() {
    super("players");
  }

  public async register(player: PlayerRegistrationDto): Promise<PlayerDto> {
    return await HttpConnection.postAsync(player, `${player.environmentIdentifier}/players/json/register`, false);
  }
}