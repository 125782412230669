import React, {useEffect, useState} from "react";
import styles from "./RecentEnvironments.scss";
import {useTranslation} from "react-i18next";
import {EnvironmentDto} from "../Models/Dto/EnvironmentDto";
import {Cookie} from "../wwwroot/Scripts/Util/Cookie";
import {TdmApi} from "../wwwroot/Scripts/Controllers/TdmApi";
import Avatar from "./Avatar";
import {UserDto} from "../Models/Dto/UserDto";
import Md5 from "../wwwroot/Scripts/Util/Md5";

interface RecentEnvironmentsProps {
  onSearchFocusChanged: (focused: boolean) => void;
}

//ReactComponentIgnore
const RecentEnvironments: React.FC<RecentEnvironmentsProps> = ({onSearchFocusChanged}) => {
  const [user, setUser] = useState<UserDto>();
  const [environments, setEnvironments] = useState<EnvironmentDto[]>([]);
  const [query, setQuery] = useState("");
  const {t} = useTranslation();

  let searchTimeout: NodeJS.Timeout = null;
  useEffect(() => {
    TdmApi.users.getCurrentUser().then(user => {
      setUser(user);
      setFromCookie(user);
    });

  }, []);

  function setFromCookie(user: UserDto) {
    const userEmailDigest = Md5.hashToBase64(user.emailAddress)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
    //AFAIK, -_ are both cookie-safe. /= definitely aren't (C# will complain), so let's not use that dialect of base64

    const recentEnvironments = Cookie.getCookie("recentEnvironmentIdentifiers_" + userEmailDigest)?.split(",") ?? [];
    const environmentPromises = recentEnvironments.map(environmentId => TdmApi.environments.get(environmentId));
    Promise.allSettled(environmentPromises).then((results) => {
      const successResults = results.filter(result => result.status === "fulfilled") as PromiseFulfilledResult<EnvironmentDto>[];
      setEnvironments(successResults.map(result => result.value));
    });
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.value === "")
      setFromCookie(user);
    else {
      if (searchTimeout !== null) {
        clearTimeout(searchTimeout);
        searchTimeout = null;
      }

      searchTimeout = setTimeout(() => {
        TdmApi.environments.search(event.target.value).then(setEnvironments);
        searchTimeout = null;
      }, 400);
    }
    setQuery(event.currentTarget.value);
  }


  return (
    <div className={styles.recentEnvironments} onClick={event => event.stopPropagation()}>
      <input type={"text"} autoFocus placeholder={t("search")} value={query} onChange={onChange}
             className={styles.search}
             onFocus={() => onSearchFocusChanged(true)} onBlur={() => onSearchFocusChanged(false)}/>
      <span className={styles.title}>{query === "" ? t("Recent environments") : t("Search results")}</span>
      <div className={styles.environmentList}>
        {environments.length > 0
          ? environments.map(environment =>
            <a key={environment.identifier} href={`/${environment.identifier}`} className={styles.environment}>
              <Avatar environment={environment} className={styles.avatar}/>
              <span className={styles.name}>{environment.name}</span>
            </a>
          )
          : <span>{t("No environments found")}</span>
        }
      </div>
    </div>
  );
};

export default RecentEnvironments;