import {HttpConnection} from "../Designer/RequestCreator";
import {PlayableContentDto} from "../../../Models/Dto/PlayableContentDto";


export class ContentController {
  protected readonly route: string;

  constructor() {
    this.route = "Content";
  }

  public async get(contentId: string): Promise<PlayableContentDto> {
    return await HttpConnection.getAsync(contentId, `${this.route}/json`);
  }
}