import {HttpConnection} from "../Designer/RequestCreator";
import {CspResultDto} from "../../../Models/Dto/CspResultDto";
import dayjs from "dayjs";
import type {Dayjs} from 'dayjs';
export class UtilController{

  public async utcTime (): Promise<Dayjs> {
      const stringValue = await HttpConnection.getAsync(null, "utctime", false);
      return dayjs(stringValue);
  }

  public async getCspHeaders(url: string): Promise<CspResultDto>{
    return await HttpConnection.getAsync(url, "FindCspHeader", false) as CspResultDto;
  }
}