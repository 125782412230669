export class Cookie {
  static getCookie(name: string){
    return decodeURIComponent(document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null);
  }

  static addCookie(name: string, value: string, global: boolean = false){
    document.cookie = `${name}=${value}; ${global ? "path=/": ""}`;
  }

  static deleteCookie(name: string) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}