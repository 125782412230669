import React, {createContext, useContext, useState} from "react";

interface BreadcrumbPart {
  name: string;
  path?: string;
}

interface BreadcrumbParams {
  parts: BreadcrumbPart[];
  base?: "currentEnvironment" | null;
}

interface HeaderParams {
  compact?: boolean;
}

type HeaderType = {
  breadcrumb?: BreadcrumbParams;
  updateBreadcrumb: (breadcrumbParams: BreadcrumbParams) => void;
  header?: HeaderParams;
  updateHeader: (headerParams: HeaderParams) => void;
}

const HeaderContext = createContext<HeaderType>({} as HeaderType);

const HeaderContextProvider = ({children}) => {
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbParams>({parts: [{name: 'Home', path: '/environments'}]});
  const [header, setHeader] = useState<HeaderParams>({});

  function updateBreadcrumb(params: BreadcrumbParams) {
    // console.log("updating breadcrumb", params);
    setBreadcrumb(params);
  }

  function updateHeader(params: HeaderParams) {
    setHeader(params);
  }


  const contextValue: HeaderType = {
    breadcrumb: breadcrumb,
    updateBreadcrumb: updateBreadcrumb,
    header: header,
    updateHeader: updateHeader
  };

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>);
};

const useHeader = () => useContext(HeaderContext);

export {useHeader};
export default HeaderContextProvider;