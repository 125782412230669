const defaultImage = "/img/shared/template-placeholder.svg";
const powerPointThumbnail = "/img/shared/icons/toolbar/icon-powerpoint.svg";
const audioThumbnail = "/img/shared/icons/toolbar/icon-audio.svg";
const videoThumbnail = "/img/shared/icons/toolbar/icon-video.svg";
export {defaultImage};

export namespace Defaults {
    export const TDM_LOGO = "/img/shared/logo-tdm-sm.svg";
    export const IMAGE_URL = defaultImage;
    
    export const POWERPOINT_THUMBNAIL = powerPointThumbnail;
    export const AUDIO_THUMBNAIL = audioThumbnail;
    export const VIDEO_THUMBNAIL = videoThumbnail;
    export const IMAGE_URL_FHD = "https://place-hold.it/1920x1080/C3C/000.png";
}
