import {addPageFunction} from '../Pages';
import $ from 'jquery';

addPageFunction('home', () => {
  const featureContainer = document.querySelector('.feature-container');
  const focusStamp = document.querySelector('.stamp.focus');
  const elementsToObserve = document.querySelectorAll('.template-information');
  const focusStampParent = focusStamp?.parentNode as HTMLElement;

  // const iso0 = new Isotope('.feature-group', {
  //   itemSelector: '.feature-item',
  //   stamp: '.stamp',
  //   layoutMode: 'masonry',
  //   masonry: {
  //     columnWidth: '.grid-sizer',
  //     gutter: '.gutter-sizer'
  //   }
  // });


  if (featureContainer) {
    featureContainer.addEventListener('click', (event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains('feature-item') && !target.classList.contains('focused')) {
        const curFocus = focusStamp?.querySelector('.feature-item') as HTMLElement;
        if (curFocus) {
          curFocus.classList.remove('focused');
          target.classList.add('focused');
          focusStampParent?.insertBefore(target, curFocus.nextSibling);
          focusStamp?.prepend(target);
        }
      }
    });
  }

  // new Isotope("#featured-clients .image-container-1", {
  //   itemSelector: '.image-tile',
  //   layoutMode: 'fitRows',
  //   originLeft: false,
  //   originTop: false,
  // });
  // new Isotope("#featured-clients .image-container-2", {
  //   itemSelector: '.image-tile',
  //   layoutMode: 'fitRows',
  //   originLeft: false,
  // });

  // observe when elements come into viewport, and toggle class to start animations
  const observer = new IntersectionObserver(entries => {
    entries.forEach(({ isIntersecting, target }) => {
      if (isIntersecting) {
        if (target.parentElement?.classList.contains('reverse')) {
          target.classList.add(...['fade-in-right']);
        } else {
          target.classList.add(...['fade-in-left']);
        }
      } else {
        target.classList.remove(...['fade-in-right', 'fade-in-left']);
      }
    });
  });

  for (const el of elementsToObserve) {
    observer.observe(el);
  }

  const testimonialCarousel = document.getElementById('testimonial-carousel') as Element;
  const quoteImages = document.getElementsByClassName('quote-image');
  testimonialCarousel.addEventListener('slide.bs.carousel', event => {
    quoteImages[event.from].classList.remove('active');
    quoteImages[event.to].classList.add('active');
  });


  const industriesMenu = $("#industries .menu");
  const industriesContent = $("#industries .content");
  industriesMenu.on('click', '.menu-item:not(.active)', ev => {
    const target = $(ev.target).data('target');
    $(industriesContent).find(target).addClass('active')
      .siblings().removeClass('active');

    $(ev.target).addClass('active').siblings().removeClass('active');
  });
});